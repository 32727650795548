.MenuDiv {
    position: -webkit-sticky;
    position: sticky;
    z-index: 5000;
    font-family: 'Aboreto';
    top: 0;
    background-color: rgba(245, 245, 245, .95);
}

.PhotoContainer {
    position: -webkit-sticky;
    position: sticky;
    z-index: -1000;
    background-image: url('../../Assets/background.png');
    top: 0;
}

.MainContentContainer {
    z-index: 1000;
    background-image: url('../../Assets/background.png');
}

.LogoTab {
    width: 4.5vh;
    height: 4.5vh;
}

.MarginDiv {
    position: relative;
    top: -7.5vh;
}

.Menu {
    font-family: 'Aboreto';
}

.Title {
    font-size: 4vh;
    font-family: 'Aboreto';
}

.Description {
    font-size: 3vh;
    font-family: 'Montserrat';
    text-align: left;
}

.FAQDiv {
    max-width: 600px;
    text-align: left;
}

@media only screen and (max-width:600px) {
    .Title {
        font-size: 3vh;
    }

    .Description {
        font-size: 2vh;
    }
}