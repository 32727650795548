.gridStyle {
    padding: 15px;
}

.hotelName {
    font-family: 'Aboreto';
    text-align: center;
    font-size: 4.5vh;
    margin-bottom: 2vh;
    font-weight: 400;
}

.detailsAddress {
    display: flex;
    text-align: center;
    word-wrap: normal;
    font-size: 3vh;
}

.link {
    font-family: 'Montserrat';
    font-size: 3vh;
    margin-bottom: 3vh;
    color: darkslateblue;
}

.map {
    width: 95%;
    max-width: 900px;
}

.mapButtons {
    width: 10vw;
    margin: 2vw;
}

.reservationsInstructions {
    font-family: 'Montserrat';
    text-align: center;
    word-wrap: normal;
    font-size: 3vh;
}

.reservationsInstructions .reservationsLinks {
    color: darkslateblue;
}

@media only screen and (max-width:600px) {
    .detailsText {
        align-items: center;
        transform: translateY(0%);

    }

    .date {
        font-size: 6vw;
    }

    .hotelName {
        justify-content: center;
        font-size: 3vh;
    }

    .detailsDate {
        font-family: 'Montserrat';
        font-size: 5vw;
        margin-bottom: 1vh;
    }


    .detailsTitle {
        font-size: 7vw;
        margin-top: 5px;
        margin-bottom: 1vh;
    }

    .detailsAddress {
        font-size: 2vh;
        text-align: center;
    }

    .link {
        font-size: 2vh;
    }

    .logo {
        width: 60vw;
    }

    .titleContainer {
        max-width: 60vw;
    }

    .map {
        width: 95%;
        max-width: 900px;
    }

    .mapButtons {
        width: 30vw;
        margin: 2vh;
    }


    .reservationsInstructions {
        font-family: 'Montserrat';
        font-size: 2vh;
    }
}