.photoComponent {
    font-family: 'Aboreto';
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.photo {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    object-position: 0 20%;
    animation: fadeIn 1000ms;
    -webkit-animation: fadeIn 1000ms;
    -moz-animation: fadeIn 1000ms;
    -o-animation: fadeIn 1000ms;
    -ms-animation: fadeIn 1000ms;
}

.titleContainer {
    position: absolute;
    padding: 5px;
    top: 3vh;
    right: 10px;
    width: fit-content;
    max-width: 40vw;
    height: fit-content;
    max-height: 40vh;
    animation: fadeIn1 1000ms 700ms;
    -webkit-animation: fadeIn1 1000ms 700ms;
    -moz-animation: fadeIn1 1000ms 700ms;
    -o-animation: fadeIn1 1000ms 700ms;
    -ms-animation: fadeIn1 1000ms 700ms;
    opacity: 0;
    animation-fill-mode: forwards;
    /* background-color: rgba(25, 25, 25, .4); */
    border-radius: 15px;
}

.titleContainer .title {
    color: black;
    font-size: 4.5vw;
    text-align: right;
    animation: fadeIn1 1000ms 1100ms;
    -webkit-animation: fadeIn1 1000ms 1100ms;
    -moz-animation: fadeIn1 1000ms 1100ms;
    -o-animation: fadeIn1 1000ms 1100ms;
    -ms-animation: fadeIn1 1000ms 1100ms;
    opacity: 0;
    animation-fill-mode: forwards;
}

.titleContainer .date {
    text-align: right;
    color: black;
    font-size: 2vw;
    text-align: right;
    font-family: 'Montserrat';
    font-weight: 300;
    animation: fadeIn2 1000ms 1500ms;
    -webkit-animation: fadeIn2 1000ms 1500ms;
    -moz-animation: fadeIn2 1000ms 1500ms;
    -o-animation: fadeIn2 1000ms 1500ms;
    -ms-animation: fadeIn2 1000ms 1500ms;
    opacity: 0;
    animation-fill-mode: forwards;
}

@media only screen and (max-width:600px) {
    .detailsText {
        align-items: center;
        transform: translateY(0%);

    }

    .date {
        font-size: 6vw;
    }

    .haciendaTitle {
        justify-content: center;
        font-size: 5.5vw;
    }

    .titleContainer {
        max-width: 60vw;
    }

    .titleContainer .date {
        font-size: 4vw;
    }

    .titleContainer .title {
        font-size: 8vw;
    }

    .reservationsInstructions {
        font-family: 'Montserrat';
        font-size: 3.5vw;
    }
}