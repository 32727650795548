.countdownTimer {
    font-family: 'Montserrat';
    padding: 10px;
}

.countdownTimer .values {
    width: 8vw;
    background-color: rgba(100, 100, 100, 0.2);
    display: inline-block;
    font-size: 2vw;
    margin: 5px;
    border-radius: 20px;

}

.countdownTimer .values .numbers {
    font-size: 2vw;
    margin: 0px;
    font-weight: 400;
}

@media only screen and (max-width:600px) {
    .countdownTimer .values {
        width: 15vw;
        background-color: rgba(100, 100, 100, 0.2);
        display: inline-block;
        font-size: 3vw;
        margin: 5px;
        border-radius: 10px;
    }

    .countdownTimer .values .numbers {
        font-size: 4vw;
        margin: 0px;
        font-weight: 400;
    }
}