.GiftComponentCard {
    padding: 20px;
    margin: 0px;
    background-color: whitesmoke;
    height: 40vh;
    border-radius: 10%;
}

.GiftComponentCard .logo {
    max-width: 80%;
    object-fit: scale-down;
}

.giftIntro {
    font-size: 3vh;
}

.gridStyle {
    padding: 15px;
    margin: 0 !important;
    font-family: 'Montserrat';
    font-size: 1.8vh;
}

@media only screen and (max-width:600px) {
    .giftIntro {
        font-size: 2vh;
    }
}