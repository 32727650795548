.App {
  /* background-image: url('Assets/background.png'); */
  text-align: center;
}

.appFooter {
  font-family: 'Montserrat';
  background-image: url('Assets/background.png');
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 1.5vh;
}

@media only screen and (max-width:600px) {
  .appFooter {
    font-size: 1vh;
  }
}