.logo {
  width: 100%;
}

.detailsTitle {
  text-align: right;
  color: black;
  font-size: 2vw;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 300;
  animation-fill-mode: forwards;
}

.saveTheDateTitle {
  text-align: center;
  color: black;
  font-size: 8vh;
  text-align: center;
  font-family: 'Aboreto';
  font-weight: 300;
  animation-fill-mode: forwards;
}

.saveTheDate {
  background-image: url('../../Assets/background.png');
}

.detailsLink {
  font-size: 2vh;
  font-family: 'Montserrat';
  font-weight: 400;
  padding: 10;
}