.VenueComponent {
    margin-top: 15px;
    width: 100%;
}

.photos {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.VenueTitle {
    font-size: 3vh;
    font-family: 'Aboreto';
    margin: 5;
    padding: 0;
}

.VenueDescription {
    font-size: 3vh;
    font-family: 'Montserrat';
    font-weight: 400;
    margin: 10;
    padding: 0;
}

.Itinerary {
    text-align: left;
}

@media only screen and (max-width:600px) {
    .VenueTitle {
        font-size: 3vh;
    }

    .VenueDescription {
        font-size: 2vh;
    }
}