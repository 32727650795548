.detailsDate {
    font-family: 'Montserrat';
    font-size: 3vw;
    padding-bottom: 3vh;
}

@media only screen and (max-width:600px) {
    .detailsDate {
        font-family: 'Montserrat';
        font-size: 5vw;
        padding-bottom: 1vh;
    }
}