.DressCodeComponent {
    padding: 10px;
    padding-bottom: 20px;
}


.DressCodeTitle {
    font-size: 4vh;
    font-family: 'Aboreto';
}

.DressCodeDescription {
    font-size: 3vh;
    font-family: 'Aboreto';
}

.photos {
    object-fit: fill;
}

.PinterestLogo {
    margin: 20px;
    width: 5vmax;
    height: 5vmax;
}

@media only screen and (max-width:600px) {
    .DressCodeTitle {
        font-size: 3vh;
    }

    .DressCodeDescription {
        font-size: 2vh;
    }
}